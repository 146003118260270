// index.js
import { Col, Row } from "antd";
import Image from "next/image";
import Typography from "../../../components/Typography";
import ScheduleForm from "../../CabinetsPage/Schedule/ScheduleForm";
import { Container, Content, FormContainer, FormHeading } from "./styled";

export default function GetStarted({ formId }) {
  return (
    <Container>
      <Row gutter={32}>
        <Col xs={24} md={24} lg={16}>
          <Image
            src="/images/closets-home/get-started-new.jpg"
            alt="designer standing next to a custom home media center"
            layout="responsive"
            width="1146"
            height="765"
            quality={85}
          />
        </Col>
        <Col xs={24} md={24} lg={8}>
          <Content>
            <FormContainer>
              <FormHeading>
                UNCOMPROMISED QUALITY | EXPANSIVE SELECTION<br />
                UNPARALLELED SERVICE
              </FormHeading>
              <ScheduleForm formId={formId} submitText="GET STARTED" />
            </FormContainer>
            <div className="title-info">
              <Typography
                textAlign="center"
                fontWeight="bold"
                margin={[24, 0, 0, 0]}
              >
                Have immediate questions? Call us!
              </Typography>
              <Typography textAlign="center" fontSize="1.7rem" fontWeight="bold">
                <a style={{ color: "black" }} href="tel:18665802280">
                  1-866-580-2280
                </a>
              </Typography>
            </div>
          </Content>
        </Col>
      </Row>
    </Container>
  );
}
